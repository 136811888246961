import styled, { createGlobalStyle, css, keyframes } from 'styled-components';


const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: #1a1a1a;
    color: #e0e0e0;
    font-family: 'Roboto', sans-serif;
  }

  * {
    box-sizing: border-box;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #2c2c2c;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const AppContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(135deg, #1a1a1a, #2c2c2c);
`;

export const Header = styled.header`
  background-color: rgba(44, 44, 44, 0.9);
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  position: sticky;
  top: 0;
  z-index: 1000;
`;

export const Title = styled.h1`
  margin: 0;
  font-size: 2rem;
  font-weight: 700;
  background: linear-gradient(45deg, #3498db, #2ecc71, #e74c3c, #f39c12);
  background-size: 300% 300%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  animation: ${gradientAnimation} 10s ease infinite;
`;

export const NewPollLink = styled.a`
  color: #fff;
  text-decoration: none;
  background-color: #3498db;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-weight: 500;
  transition: all 0.3s ease;
  cursor: pointer;
  

  &:hover {
    background-color: #2980b9;
    box-shadow: 0 2px 8px rgba(52, 152, 219, 0.3);
  }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOutDown = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20px);
  }
`;

export const Content = styled.main`
  flex-grow: 1;
  width: 60%;
  min-width: 300px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #2c2c2c;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  animation: ${fadeIn} 0.3s ease-in-out;

  @media (max-width: 768px) {
    width: 90%;
    padding: 1rem;
  }
`;

export const CandidateList = styled.div`
  background-color: #3c3c3c;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  position: relative;
`;

export const CandidateItem = styled.div`
  user-select: none;
  padding: 1.2rem;
  margin-bottom: 0.8rem;
  background-color: ${props => props.isSelected ? '#4a4a4a' : '#2c2c2c'};
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  font-size: 1.1rem;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const SubmitButton = styled.button`
  display: block;
  width: 100%;
  padding: 1.2rem;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #2980b9;
    box-shadow: 0 2px 8px rgba(52, 152, 219, 0.3);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ResetButton = styled(SubmitButton)`
  background-color: #e74c3c;
  margin-top: 1rem;

  &:hover {
    background-color: #c0392b;
  }
`;

export const RankNumber = styled.span`
  display: inline-block;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  background-color: #3498db;
  color: white;
  border-radius: 50%;
  margin-right: 10px;
`;

export const PollCreationForm = styled.div`
  background-color: #3c3c3c;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
`;

export const CandidateInput = styled.div`
  display: flex;
  margin-bottom: 1rem;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const RemoveCandidateButton = styled.button`
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 0.8rem 1rem;
  margin-left: 0.5rem;
  cursor: pointer;
  border-radius: 4px;
  font-size: 0.9rem;

  &:hover {
    background-color: #c0392b;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 0.5rem;
  }
`;

export const Input = styled.input`
  flex-grow: 1;
  padding: 0.8rem;
  border: none;
  border-radius: 4px;
  background-color: #2c2c2c;
  color: #e0e0e0;
  font-size: 1rem;

  &::placeholder {
    color: #888;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const AddCandidateButton = styled(SubmitButton)`
  background-color: #2ecc71;
  margin-bottom: 1rem;

  &:hover {
    background-color: #27ae60;
  }
`;


export const PollNameInput = styled(Input)`
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
  width: 100%;
`;

export const PreviousPollsDropdown = styled.select`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const WelcomeMessage = styled.h2`
  font-size: 2rem;
  color: #3498db;
  margin-bottom: 1rem;
`;

export const ExplanationText = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
`;

export const CreatePollButton = styled(SubmitButton)`
  background-color: #3498db;
  
  &:hover {
    background-color: #347cdb;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const LinkButton = styled.a`
  display: inline-block;
  background-color: #3498db;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2980b9;
  }
`;

export const CopyButton = styled.button`
  background-color: #2ecc71;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #27ae60;
  }
`;

export const PollLinkDisplay = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  background-color: #3c3c3c;
  border-radius: 8px;
  word-break: break-all;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
`;

export const SecretLinkDisplay = styled(PollLinkDisplay)`
  margin-top: 1rem;
  padding: 1rem;
  background-color: #3c3c3c;
  border-radius: 8px;
  word-break: break-all;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

export const  LinkLabel = styled.strong`
  margin-bottom: 0.5rem;
`;

export const  LinkTextBox = styled.input`
  width: 100%;
  padding: 0.75rem;
  background-color: #3c3c3c;
  border: 1px solid #555;
  border-radius: 4px;
  color: #e0e0e0;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  outline: none;
  
  &:focus {
    border-color: #3498db;
  }
`;

export const  ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const VotesCountText = styled.h4`
  font-size: 1.3rem;
  text-align: center;
`;

export const ThankYouMessage = styled.h2`
  font-size: 2rem;
  color: #2ecc71;
  text-align: center;
  margin-bottom: 1rem;
`;

export const ThankYouContent = styled.div`
  background-color: #3c3c3c;
  border-radius: 8px;
  padding: 2rem;
  text-align: center;
`;

export const InfoText = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 1rem;
`;

export const ExampleContainer = styled.div`
  position: relative;
  height: 200px;
  margin-bottom: 2rem;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 180px;
  }
`;

export const ExampleCard = styled.div`
  background: linear-gradient(135deg, #3498db, #8e44ad);
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  width: calc(33.333% - 20px);
  margin: 0 10px;
  flex-shrink: 0;

  @media (max-width: 768px) {
    width: calc(100% - 20px);
    margin: 0 10px;
  }
`;

export const ExampleTitle = styled.h3`
  font-size: 1.2rem;
  color: #ffffff;
  margin-bottom: 0.5rem;
`;

export const ExampleText = styled.p`
  font-size: 1rem;
  color: #e0e0e0;
`;