import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import {
  GlobalStyle,
  AppContainer,
  Header,
  Title,
  Content,
  SubmitButton,
  CandidateList,
  CandidateItem,
  WelcomeMessage,
  ExplanationText,
  VotesCountText
} from './styles';

const apiUrl = '/api';

function ClosePoll() {
  const { secretKey } = useParams();
  const [poll, setPoll] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPollData();
  }, [secretKey]);

  const fetchPollData = async () => {
    try {
      const response = await fetch(`${apiUrl}/polls/close/${secretKey}`);
      if (!response.ok) {
        throw new Error('Failed to fetch poll data');
      }
      const pollData = await response.json();
      setPoll(pollData);
    } catch (error) {
      console.error('Error fetching poll data:', error);
      setError('Invalid or expired secret key');
    }
  };

  const handleClosePoll = async () => {
    try {
      const response = await fetch(`${apiUrl}/polls/${poll.id}/close/${secretKey}`, {
        method: 'POST',
      });

      if (!response.ok) {
        throw new Error('Failed to close poll');
      }

      // Redirect to the results page
      navigate(`/poll/${poll.id}`);
    } catch (error) {
      console.error('Error closing poll:', error);
      alert('Failed to close poll. Please try again.');
    }
  };

  if (error) {
    return (
      <>
        <GlobalStyle />
        <AppContainer>
          <Header>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <Title>Ranked Choice</Title>
            </Link>
          </Header>
          <Content>
            <WelcomeMessage>Error</WelcomeMessage>
            <ExplanationText>{error}</ExplanationText>
          </Content>
        </AppContainer>
      </>
    );
  }

  if (!poll) {
    return (
      <>
        <GlobalStyle />
        <AppContainer>
          <Header>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <Title>Ranked Choice</Title>
            </Link>
          </Header>
          <Content>
            <WelcomeMessage>Loading...</WelcomeMessage>
          </Content>
        </AppContainer>
      </>
    );
  }

  return (
    <>
      <GlobalStyle />
      <AppContainer>
        <Header>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <Title>Ranked Choice Voting</Title>
          </Link>
        </Header>
        <Content>
          <WelcomeMessage>Close Poll: {poll.title}</WelcomeMessage>
          <ExplanationText>Are you sure you want to close this poll?</ExplanationText>
          <ExplanationText>Candidates:</ExplanationText>
          <CandidateList>
            {poll.candidates.map((candidate, index) => (
              <CandidateItem key={index}>{candidate}</CandidateItem>
            ))}
          </CandidateList>
          <SubmitButton onClick={handleClosePoll}>Close Poll</SubmitButton>
          <VotesCountText>{poll.votes_count} votes submitted.</VotesCountText>
        </Content>
      </AppContainer>
    </>
  );
}

export default ClosePoll;