import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  GlobalStyle,
  AppContainer,
  Header,
  Title,
  Content,
  PollCreationForm,
  PollNameInput,
  CandidateInput,
  Input,
  AddCandidateButton,
  RemoveCandidateButton,
  CreatePollButton,
  PreviousPollsDropdown,
  PollLinkDisplay,
  SecretLinkDisplay,
  WelcomeMessage,
  ExplanationText,
  LinkButton,
  CopyButton,
  LinkContainer,
  LinkLabel,
  LinkTextBox,
  ButtonContainer
} from './styles';

const apiUrl = '/api';

const SuccessMessage = styled(WelcomeMessage)`
  color: #2ecc71;
  margin-bottom: 2rem;
`;

const InfoBox = styled.div`
  background-color: #3a3a3a;
  border-left: 5px solid #3498db;
  padding: 1.5rem;
  margin-bottom: 2rem;
  border-radius: 5px;
`;

const InfoTitle = styled.h3`
  color: #3498db;
  margin-bottom: 1rem;
  font-size: 1.2rem;
`;

const InfoText = styled(ExplanationText)`
  margin-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 1.4;
`;

const CopyFeedback = styled.span`
  color: #2ecc71;
  margin-right: 10px;
  font-size: 0.9rem;
  opacity: ${props => props.visible ? 1 : 0};
  transition: opacity 0.3s ease-in-out;
`;


function CreatePoll() {
  const [pollName, setPollName] = useState('');
  const [candidates, setCandidates] = useState([
    { id: 'new1', name: '' },
    { id: 'new2', name: '' },
  ]);
  const [savedPolls, setSavedPolls] = useState([]);
  const [createdPoll, setCreatedPoll] = useState(null);
  const [copiedLink, setCopiedLink] = useState(null);

  useEffect(() => {
    loadSavedPolls();
  }, []);

  const loadSavedPolls = () => {
    const polls = JSON.parse(localStorage.getItem('savedPolls')) || [];
    setSavedPolls(polls.slice(0, 5)); // Only keep the last 5 polls
  };

  const savePoll = (pollData) => {
    const polls = JSON.parse(localStorage.getItem('savedPolls')) || [];
    
    // Check if a poll with the same title already exists
    const existingPollIndex = polls.findIndex(poll => poll.title === pollData.title);
    
    if (existingPollIndex !== -1) {
      // If it exists, replace the old poll with the new one
      polls[existingPollIndex] = pollData;
    } else {
      // If it doesn't exist, add the new poll to the beginning of the array
      polls.unshift(pollData);
    }
    
    // Ensure we only keep the last 5 polls
    const updatedPolls = polls.slice(0, 5);
    
    localStorage.setItem('savedPolls', JSON.stringify(updatedPolls));
    loadSavedPolls(); // Reload saved polls
  };

  const handleCreatePoll = async () => {
    const validCandidates = candidates.filter(c => c.name.trim() !== '');
    if (validCandidates.length < 2) {
      alert("Please enter at least two candidates.");
      return;
    }
  
    if (!pollName.trim()) {
      alert("Please enter a poll name.");
      return;
    }
  
    try {
      console.log('API URL:', apiUrl);
      const response = await fetch(`${apiUrl}/polls`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          title: pollName,
          candidates: validCandidates.map(c => c.name),
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to create poll');
      }
  
      const pollData = await response.json();
      setCreatedPoll({
        id: pollData.id,
        secretKey: pollData.secret_key
      });

      // Save the newly created poll
      savePoll({
        id: pollData.id,
        title: pollName,
        candidates: validCandidates.map(c => c.name)
      });
    } catch (error) {
      console.error('Error creating poll:', error);
      alert('Failed to create poll. Please try again.');
    }
  };

  const handlePreviousPollSelect = (event) => {
    const selectedPoll = savedPolls.find(poll => poll.id === event.target.value);
    if (selectedPoll) {
      setPollName(selectedPoll.title);
      setCandidates(selectedPoll.candidates.map((name, index) => ({
        id: `new${index}`,
        name
      })));
    }
  };

  const handleCandidateChange = (id, value) => {
    setCandidates(prev => 
      prev.map(c => c.id === id ? { ...c, name: value } : c)
    );
  };
  
  const addCandidate = () => {
    setCandidates(prev => [
      ...prev,
      { id: `new${Date.now()}`, name: '' }
    ]);
  };
  
  const removeCandidate = (id) => {
    setCandidates(prev => prev.filter(c => c.id !== id));
  };

  const copyToClipboard = (text, linkType) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedLink(linkType);
      setTimeout(() => setCopiedLink(null), 2000); // Reset after 2 seconds
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };
  

  return (
    <>
      <GlobalStyle />
      <AppContainer>
        <Header>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <Title>Ranked Choice Voting</Title>
          </Link>
        </Header>
        <Content>
          {!createdPoll ? (
            <PollCreationForm>
              <SuccessMessage>Create a New Poll</SuccessMessage>
              <LinkLabel>Poll Name:</LinkLabel>
              <PollNameInput
                type="text"
                value={pollName}
                onChange={(e) => setPollName(e.target.value)}
                placeholder="Enter poll name"
              />
              <PreviousPollsDropdown onChange={handlePreviousPollSelect}>
                <option value="">Select a previous poll</option>
                {savedPolls.map(poll => (
                  <option key={poll.id} value={poll.id}>{poll.title}</option>
                ))}
              </PreviousPollsDropdown>
              {candidates.map((candidate, index) => (
                <CandidateInput key={candidate.id}>
                  <Input
                    type="text"
                    value={candidate.name}
                    onChange={(e) => handleCandidateChange(candidate.id, e.target.value)}
                    placeholder={`Candidate ${index + 1}`}
                  />
                  {candidates.length > 2 && (
                    <RemoveCandidateButton onClick={() => removeCandidate(candidate.id)}>
                      Remove
                    </RemoveCandidateButton>
                  )}
                </CandidateInput>
              ))}
              <AddCandidateButton onClick={addCandidate}>Add Candidate</AddCandidateButton>
              <CreatePollButton onClick={handleCreatePoll}>Create Poll</CreatePollButton>
            </PollCreationForm>
          ) : (
            <div>
              <SuccessMessage>Poll Created Successfully!</SuccessMessage>
              
              <LinkContainer>
                <LinkLabel>Share this link with voters:</LinkLabel>
                <LinkTextBox 
                  type="text" 
                  value={`${window.location.origin}/poll/${createdPoll.id}`} 
                  readOnly
                />
                <ButtonContainer>
                  <CopyFeedback visible={copiedLink === 'voter'}>Copied!</CopyFeedback>
                  <CopyButton onClick={() => copyToClipboard(`${window.location.origin}/poll/${createdPoll.id}`, 'voter')}>
                    Copy
                  </CopyButton>
                </ButtonContainer>
              </LinkContainer>

              <InfoBox>
                <InfoTitle>Important: Save Your Admin Link</InfoTitle>
                <InfoText>
                  The admin link below is used to close the poll and view results. Please save it securely:
                </InfoText>
                <InfoText>
                  • This link is only shown once, right now.
                </InfoText>
                <InfoText>
                  • Store it in a safe place you'll remember.
                </InfoText>
                <InfoText>
                  • Don't share it with anyone who shouldn't have admin access.
                </InfoText>
              </InfoBox>

              <LinkContainer>
                <LinkLabel>Admin Link:</LinkLabel>
                <LinkTextBox 
                  type="text" 
                  value={`${window.location.origin}/close-poll/${createdPoll.secretKey}`} 
                  readOnly
                />
                <ButtonContainer>
                <CopyFeedback visible={copiedLink === 'admin'}>Copied!</CopyFeedback>
                  <CopyButton onClick={() => copyToClipboard(`${window.location.origin}/close-poll/${createdPoll.secretKey}`, 'admin')}>
                    Copy
                  </CopyButton>
                </ButtonContainer>
              </LinkContainer>
            </div>
          )}
        </Content>
      </AppContainer>
    </>
  );
}

export default CreatePoll;