import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  GlobalStyle,
  AppContainer,
  Header,
  Title,
  Content,
  WelcomeMessage,
  ExplanationText,
  CreatePollButton,
} from './styles';

const apiUrl = '/api';

const ExampleContainer = styled.div`
  position: relative;
  height: 200px;
  margin-bottom: 2rem;
  overflow: hidden;
`;

const CarouselWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
`;

const ExampleCard = styled.div`
  background: linear-gradient(135deg, #3498db, #8e44ad);
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  width: ${props => props.isMobile ? 'calc(100% - 20px)' : 'calc(33.333% - 20px)'};
  margin: 0 10px;
  flex-shrink: 0;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const ExampleTitle = styled.h3`
  font-size: 1.2rem;
  color: #ffffff;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ExampleText = styled.p`
  font-size: 1rem;
  color: #e0e0e0;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}



const examples = [
  {
    title: "🍕 Pizza Toppings Showdown",
    text: "Settle the age-old debate: pineapple or no pineapple?"
  },
  {
    title: "🎬 Movie Night Picker",
    text: "Action, romance, or that weird indie film your friend keeps recommending?"
  },
  {
    title: "🐱 Office Pet Mascot",
    text: "Choose between Mr. Whiskers, Bark Twain, or Fish Sticks the goldfish!"
  },
  {
    title: "🏖️ Vacation Destination",
    text: "Beach resort, mountain retreat, or bustling city adventure?"
  },
  {
    title: "📚 Book Club Selection",
    text: "Classic literature, modern bestseller, or hidden gem?"
  },
  {
    title: "🍽️ Restaurant for Team Dinner",
    text: "Italian, sushi, or that new fusion place everyone's talking about?"
  },
  {
    title: "🎨 Office Wall Color",
    text: "Calming blue, energizing yellow, or sleek modern gray?"
  },
  {
    title: "🎵 Karaoke Night Song",
    text: "80s power ballad, current pop hit, or timeless classic?"
  },
  {
    title: "🏆 Employee of the Month",
    text: "Who's been crushing it lately? Time to give credit where it's due!"
  },
  {
    title: "🎉 Company Party Theme",
    text: "Roaring 20s, space odyssey, or tropical luau?"
  },
  {
    title: "☕ New Office Coffee Machine",
    text: "Espresso wizard, cold brew master, or good old drip?"
  },
  {
    title: "🌿 Office Plant Selection",
    text: "Towering ficus, cute succulents, or low-maintenance snake plant?"
  },
  {
    title: "🎮 Game Night Choice",
    text: "Strategy board game, party video game, or classic card game?"
  },
  {
    title: "🍰 Birthday Cake Flavor",
    text: "Decadent chocolate, fruity strawberry, or exotic matcha?"
  },
  {
    title: "🏋️ Team Building Activity",
    text: "Escape room challenge, cooking class, or outdoor adventure?"
  }
];


function Home() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [randomizedExamples, setRandomizedExamples] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const carouselRef = useRef(null);

  useEffect(() => {
    const shuffledExamples = shuffleArray([...examples]);
    setRandomizedExamples([...shuffledExamples, ...shuffledExamples, ...shuffledExamples]);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }, 8000);
  
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (currentIndex >= examples.length) {
      // Reset to the beginning of the second set of examples
      setCurrentIndex(currentIndex % examples.length);
      if (carouselRef.current) {
        carouselRef.current.style.transition = 'none';
        carouselRef.current.style.transform = `translateX(-${(currentIndex % examples.length) * 100}%)`;
        // Force reflow
        carouselRef.current.offsetHeight;
        carouselRef.current.style.transition = 'transform 0.5s ease-in-out';
      }
    }
  }, [currentIndex]);

  return (
    <>
      <GlobalStyle />
      <AppContainer>
        <Header>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <Title>Ranked Choice Voting</Title>
          </Link>
        </Header>
        <Content>
          <WelcomeMessage>Welcome to Ranked Choice Voting!</WelcomeMessage>
          <ExplanationText>
            Ranked choice voting is a fun and fair way to make decisions. Voters rank candidates by preference, ensuring the most widely accepted option wins!
          </ExplanationText>
          <ExampleContainer>
            <CarouselWrapper 
              ref={carouselRef}
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              {randomizedExamples.map((example, index) => (
                <ExampleCard key={index} isMobile={isMobile}>
                  <ExampleTitle>{example.title}</ExampleTitle>
                  <ExampleText>{example.text}</ExampleText>
                </ExampleCard>
              ))}
            </CarouselWrapper>
          </ExampleContainer>
          <Link to="/create-poll" style={{ textDecoration: 'none' }}>
            <CreatePollButton>Create Your Poll Now!</CreatePollButton>
          </Link>
        </Content>
      </AppContainer>
    </>
  );
}

export default Home;