import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import CreatePoll from './CreatePoll';
import Poll from './Poll';
import ClosePoll from './ClosePoll.js';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/create-poll" element={<CreatePoll />} />
        <Route path="/poll/:pollId" element={<Poll />} />
        <Route path="/close-poll/:secretKey" element={<ClosePoll />} /> {}
      </Routes>
    </Router>
  );
}

export default App;